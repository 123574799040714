import { CodeRedemptionApi } from "dcgo-contracts";
import { Label } from "~/lib/client/tracking";
import { useEffect } from "react";
import { useOnboarding } from "~/components/onboarding/context";
import { useRouter } from "next/router";

export const useBeginGiftOnboarding = ({
  subscribedPath,
  codeType,
  code,
  label,
  shouldRedirect,
}: {
  subscribedPath: string;

  code: string;
  codeType: CodeRedemptionApi.RedeemableCodeType | null;

  label: Label;
  shouldRedirect?: boolean;
}) => {
  const { beginOnboarding } = useOnboarding();
  const router = useRouter();
  useEffect((): void => {
    if (codeType !== "gift") return;

    beginOnboarding({
      subscribedPath,
      exitedPath: subscribedPath,
      promotionCode: code,
      mode: "gift",
      label,
    });

    if (shouldRedirect) {
      router.push("/subscribe");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [codeType]);
};
