import { PaymentMethodFieldProps } from "./types";
import Link from "next/link";
import React from "react";
type TermsOfServiceFieldProps = PaymentMethodFieldProps;

/** Displays and requires acceptance of terms of service. */
export const TermsOfServiceField: React.FC<TermsOfServiceFieldProps> = (
  props
) => {
  if (!props.register || !props.formErrors) {
    return null;
  }

  return (
    <>
      <div className="form__row form__row--last form__row--legal">
        <input
          id="SubConsent"
          className="form__input"
          name="SubConsent"
          type="checkbox"
          ref={props.register({
            required:
              "You must agree to the terms of membership before continuing.",
          })}
        />
        <label
          className="form__row__label form__row__label--checkbox"
          htmlFor="SubConsent"
        >
          I agree to Dance Church&apos;s{" "}
          <Link href="/legal/terms">
            <a target="_blank" tabIndex={-1}>
              Terms of Service
            </a>
          </Link>{" "}
          and acknowledge the{" "}
          <Link href="/legal/privacy">
            <a target="_blank" tabIndex={-1}>
              Privacy Policy
            </a>
          </Link>
          .
        </label>
      </div>
      {props.formErrors.SubConsent && (
        <div className="form__row form__row--last form__row--right">
          <div className="form__error">
            {props.formErrors.SubConsent.message}
          </div>
        </div>
      )}
    </>
  );
};
