import React from "react";
import styles from "./PromoCodeBanner.module.scss";
export const PromoCodeBanner = ({
  promotionCode,
}: {
  promotionCode: string;
}) => (
  <div className={styles.PromoCodeBanner}>
    <div className={styles.PromoCodeBannerMessage}>
      Promo Code {promotionCode.toUpperCase()} Applied
    </div>
  </div>
);
