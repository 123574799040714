import { OfferingApi } from "dcgo-contracts";
import animateScrollTo from "animated-scroll-to";
import clsx from "clsx";
import React, { FC } from "react";

interface Props {
  offerings: OfferingApi.Offering[];
  selectedOffering: OfferingApi.Offering | null;
  onOfferingSelected: (selectedId: string) => void;
  hideTitle?: boolean;
  hideWhy?: boolean;
}

export const SelectOffering: FC<Props> = ({
  offerings,
  selectedOffering,
  onOfferingSelected,
  hideTitle,
  hideWhy,
}) => {
  return (
    <div className="offerings">
      {!hideTitle && (
        <>
          <h1 className="offerings__title">
            Become a member, support dance artists.
          </h1>
          <h1 className="offerings__title__mobile">
            Become a member, support dance artists.
          </h1>
        </>
      )}
      <p className="offerings__subtitle">
        {!hideWhy && (
          <button
            onClick={() => {
              const benefitsEl = document.querySelector(
                "#membershipBenefits"
              ) as HTMLInputElement | null;

              if (benefitsEl) {
                animateScrollTo(benefitsEl, {
                  verticalOffset: -60,
                });
              }
            }}
          >
            Why Subscribe?
          </button>
        )}
      </p>
      <div className="offerings__list form__row form__row--stack">
        {offerings.map((offering) => {
          const { id, amount, cycle, name, description } = offering;
          return (
            <button
              type="button"
              className={clsx("form__row__button form__row__button--stack", {
                "form__row__button--selected": id === selectedOffering?.id,
              })}
              onClick={() => onOfferingSelected(offering.id)}
              key={id}
            >
              <span className="form__row__button__super">{name}</span>
              <span className="form__row__button__title">
                <span>${amount}</span>/{cycle}
              </span>
              <span className="form__row__button__description">
                {description?.split("\n").map((line, i) => (
                  <p key={i}>{line}</p>
                ))}
              </span>
            </button>
          );
        })}
      </div>
    </div>
  );
};
