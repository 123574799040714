"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ReactionType = void 0;
var ReactionType;
(function (ReactionType) {
    ReactionType["SWEAT"] = "sweat";
    ReactionType["SMOOTH"] = "smooth";
    ReactionType["FIRE"] = "fire";
    ReactionType["BANG"] = "bang";
    ReactionType["HYPE"] = "hype";
    ReactionType["RHYTHM"] = "rhythm";
})(ReactionType = exports.ReactionType || (exports.ReactionType = {}));
