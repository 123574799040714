import { retrieveSalesTax } from "~/lib/client/api/tax";
import { TaxApi } from "dcgo-contracts";
import { useEffect, useState } from "react";

interface SalesTaxInfo {
  amount: number;
  category: TaxApi.ProductCategory;
  zipCode: string;
}

export interface SalesTaxCalculation {
  amount: number;
  calculating: boolean;
  description: string;
  calculated: boolean;
}

const defaultState: SalesTaxCalculation = {
  amount: 0,
  calculating: false,
  description: "",
  calculated: false,
};

export const getUseSalesTax = (retrieve: typeof retrieveSalesTax) => ({
  amount,
  category,
  zipCode,
}: SalesTaxInfo) => {
  const [salesTaxInfo, setSalesTaxInfo] = useState(defaultState);

  useEffect(() => {
    if (!/[0-9]{5}/.test(zipCode) || !amount) {
      setSalesTaxInfo(defaultState);
      return;
    }

    setSalesTaxInfo({ ...defaultState, calculating: true });

    retrieve({ amount, category, zipCode }).then((response) => {
      if (response.ok) {
        const { salesTax } = response.data;
        setSalesTaxInfo({
          ...salesTax,
          calculating: false,
          calculated: true,
        });
      } else {
        setSalesTaxInfo(defaultState);
      }
    });
  }, [amount, category, zipCode]);

  return salesTaxInfo;
};

export const useSalesTax = getUseSalesTax(retrieveSalesTax);
