import { CodeRedemptionApi, OfferingApi } from "dcgo-contracts";

export interface OfferingPromotion {
  totalDue: number;
  isTrial: boolean;
  promotionCanApply?: boolean;
}
export const getOfferingPromotion = (
  offering: OfferingApi.Offering | null,
  promotion?: CodeRedemptionApi.RedeemableCode | null
): OfferingPromotion => {
  if (!offering) {
    return {
      totalDue: 0,
      isTrial: false,
      promotionCanApply: false,
    };
  }

  if (!promotion) {
    return {
      totalDue: offering.amount,
      isTrial: false,
      promotionCanApply: false,
    };
  }

  const isAllowedTrial =
    Boolean(promotion.trialDays) &&
    promotion.applicableOfferings.includes(offering.id);
  const promotionCanApply = Boolean(!promotion.trialDays || isAllowedTrial);

  const isFree = isAllowedTrial || promotion.free === "forever";

  const { percentOff = 0 } = promotion;
  const discount = isFree ? 1 : percentOff / 100;

  const totalDue = promotionCanApply
    ? offering.amount * (1 - discount)
    : offering.amount;

  return { isTrial: isAllowedTrial, totalDue, promotionCanApply };
};
