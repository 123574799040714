import { APIResponse, post, put } from ".";
import { MembershipApi } from "dcgo-contracts";

/** Create a membership if one does not exist. */
export const createMembership = (
  data: MembershipApi.CreateMembershipRequest
): Promise<APIResponse<MembershipApi.CreateMembershipResponse>> =>
  put("/account/membership", data);

/** Update an extant membership. */
export const updateMembership = (
  data: MembershipApi.UpdateMembershipRequest
): Promise<APIResponse<MembershipApi.UpdateMembershipResponse>> =>
  post("/account/membership", data);

/** Cancel a membership at billing cycle end. */
export const cancelMembership = (): Promise<
  APIResponse<MembershipApi.CancelMembershipResponse>
> => post("/account/membership/cancel", {});

/** Uncancel a membership that is set to cancel at billing cycle end. */
export const uncancelMembership = (): Promise<
  APIResponse<MembershipApi.UncancelMembershipResponse>
> => post("/account/membership/uncancel", {});
