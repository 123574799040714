"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Badge = exports.VideoLengthCategory = exports.AssetCategory = void 0;
/** Available video categories. */
var AssetCategory;
(function (AssetCategory) {
    AssetCategory["DANCE_CHURCH"] = "Dance Church";
    AssetCategory["MORE"] = "More";
    AssetCategory["TEACHER_CONTENT"] = "Teacher Content";
    AssetCategory["WORKOUTS"] = "Workouts";
})(AssetCategory = exports.AssetCategory || (exports.AssetCategory = {}));
/** Available video length categories. */
var VideoLengthCategory;
(function (VideoLengthCategory) {
    VideoLengthCategory["55ISH"] = "55-ish Minutes";
    VideoLengthCategory["30ISH"] = "30-ish Minutes";
    VideoLengthCategory["15ISH"] = "15-ish Minutes";
})(VideoLengthCategory = exports.VideoLengthCategory || (exports.VideoLengthCategory = {}));
var Badge;
(function (Badge) {
    Badge["NEW"] = "NEW";
    Badge["VIDEO"] = "VIDEO";
})(Badge = exports.Badge || (exports.Badge = {}));
