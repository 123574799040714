"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TicketStatus = void 0;
var TicketStatus;
(function (TicketStatus) {
    TicketStatus["AVAILABLE"] = "available";
    TicketStatus["AVAILABLE_TO_SUBSCRIBERS"] = "available_to_subscribers";
    TicketStatus["PRESALE"] = "presale";
    TicketStatus["SOLD_OUT"] = "sold-out";
    TicketStatus["PURCHASED"] = "purchased";
    TicketStatus["PAST"] = "past";
    TicketStatus["FUTURE"] = "future";
})(TicketStatus = exports.TicketStatus || (exports.TicketStatus = {}));
