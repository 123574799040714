import { APIResponse } from "~/lib/client/api";
import {
  cancelMembership,
  createMembership,
  uncancelMembership,
  updateMembership,
} from "~/lib/client/api/membership";
import {
  CodeRedemptionApi,
  MembershipApi,
  OfferingApi,
  PaymentMethodApi,
} from "dcgo-contracts";
import { getOfferingPromotion } from "./getOfferingPromotion";
import { Label, useTracking } from "~/lib/client/tracking";
import { retrieveAccount } from "~/lib/client/api/account";
import { updatePaymentMethod } from "~/lib/client/api/paymentMethod";
import { useAccount } from "~/lib/client/account";
import { useState } from "react";
import { v4 as uuid } from "uuid";

type Membership = MembershipApi.Membership;
type PaymentMethod = PaymentMethodApi.PaymentMethod;

interface MembershipOffering {
  code?: string;
  offeringId: string;
}

export interface CreateMembershipRequest {
  code?: string;
  offering: OfferingApi.Offering;
  paymentMethodId?: string;
  promotion: CodeRedemptionApi.RedeemableCode | null;
}

export type PromisedMembershipResponse = Promise<
  APIResponse<{ membership: Membership }>
>;

const realApi = {
  retrieveAccount,
  cancelMembership,
  updateMembership,
  uncancelMembership,
  updatePaymentMethod,
  createMembership,
};

export type MembershipApi = typeof realApi;

export interface MembershipState {
  errorMessage: string;
  membership: Membership | null;
  paymentMethod: PaymentMethod | null;
}

export interface MembershipActions {
  cancel: () => PromisedMembershipResponse;
  createMembership: (
    request: CreateMembershipRequest
  ) => PromisedMembershipResponse;
  uncancel: () => PromisedMembershipResponse;
  updatePaymentMethod: (method: { paymentMethodId: string }) => Promise<void>;
  updateMembership: (update: MembershipOffering) => PromisedMembershipResponse;
}

//TODO: combine this and useAccount. For now, just tell account to refresh when membership changes.

export const getUseMembership = (
  api: MembershipApi,
  useTracking_: typeof useTracking
) => (context: Label): [MembershipState, MembershipActions] => {
  const [
    {
      account: { paymentMethod },
      membership,
    },
    account,
  ] = useAccount();
  const [errors, setErrors] = useState<string[]>([]);
  const [idempotencyKey, setIdempotencyKey] = useState(uuid());
  const track = useTracking_();

  const handleResponse = (
    response: APIResponse<{ membership: Membership }>
  ) => {
    if (response.ok) {
      setErrors([]);
      account.setMembership(response.data.membership);
    } else {
      setErrors(response.errors.form || ["Oops. Something went wrong."]);
      setIdempotencyKey(uuid());
    }
  };

  const awaitAndSetMembership = async (
    promise: PromisedMembershipResponse,
    onSuccess = () => {}
  ) => {
    setErrors([]);
    const response = await promise;
    handleResponse(response);
    if (response.ok) onSuccess();

    return response;
  };

  return [
    {
      errorMessage: errors[0] || "",
      membership: membership || null,
      paymentMethod: paymentMethod || null,
    },
    {
      createMembership: (request: CreateMembershipRequest) => {
        const offeringPromotion = getOfferingPromotion(
          request.offering,
          request.promotion
        );

        return awaitAndSetMembership(
          api.createMembership({
            offeringId: request.offering.id,
            paymentMethodId: request.paymentMethodId,
            code: offeringPromotion.promotionCanApply
              ? request.code
              : undefined,
            idempotencyKey,
          }),
          () => {
            track.subscribed({
              cycle: request.offering.cycle,
              amount: request.offering.amount,
              label: context,
            });
          }
        );
      },
      cancel: () =>
        awaitAndSetMembership(api.cancelMembership(), () => {
          track.cancelled();
        }),
      uncancel: () =>
        awaitAndSetMembership(api.uncancelMembership(), () => {
          track.restartedSubscription();
        }),
      updatePaymentMethod: async (method: { paymentMethodId: string }) => {
        setErrors([]);
        const response = await api.updatePaymentMethod(method);
        if (response.ok) {
          account.setPaymentMethod(response.data.paymentMethod);
        } else {
          setErrors(response.errors.form || ["Oops. Something went wrong."]);
        }
      },
      updateMembership: (request: MembershipOffering) =>
        awaitAndSetMembership(api.updateMembership(request)),
    },
  ];
};

export const useMembership = getUseMembership(realApi, useTracking);
