import { useAccount } from "~/lib/client/account";
import { useOnboarding } from "~/components/onboarding/context";
import { usePromoCodeQuery } from "~/lib/client/useInitialQuery";

export const useAutoApplyPromoCode = () => {
  const { promotionCode } = useOnboarding();
  const fromQuery = usePromoCodeQuery().value;
  const [
    {
      permissions: { canSubscribeWithFreeTrial },
    },
  ] = useAccount();

  return (
    fromQuery ||
    promotionCode ||
    (canSubscribeWithFreeTrial ? "FREETRIAL7" : "")
  );
};
