import { CodeRedemptionApi } from "dcgo-contracts";
import { describePromotion } from "../ExistingMembership/membershipMessaging";
import Link from "next/link";
import React, { useState } from "react";

export const ConfirmFreeMembership = (props: {
  errorMessage?: string;
  promotion: CodeRedemptionApi.RedeemableCode;
  onConfirm: () => void;
  onCancel: () => void;
}) => {
  const { headline, subhead, description, cta } = describePromotion(
    props.promotion
  );
  const [termsState, setTermsState] = useState<
    "not-accepted" | "accepted" | "error"
  >("not-accepted");

  const errorMessage =
    termsState === "error"
      ? "You must agree to the terms of membership before continuing."
      : props.errorMessage;

  return (
    <form
      className="form confirm-free-membership"
      onSubmit={(e) => {
        e.preventDefault();
        if (termsState === "not-accepted") {
          setTermsState("error");
          return;
        }
        props.onConfirm();
      }}
    >
      <h1>{headline}</h1>
      <h2>{subhead}</h2>
      <div className="subscription_details">
        {description.map((line, i) => (
          <p className="form-row" key={i}>
            {line}
          </p>
        ))}
      </div>
      <div className="form__row form__row--last form__row--legal">
        <input
          className="form__input"
          id="terms"
          type="checkbox"
          checked={termsState === "accepted"}
          onChange={(e) =>
            setTermsState(e.target.checked ? "accepted" : "not-accepted")
          }
        />
        <label
          className="form__row__label form__row__label--checkbox"
          htmlFor="terms"
        >
          I agree to Dance Church&apos;s{" "}
          <Link href="/legal/terms">
            <a target="_blank" tabIndex={-1}>
              Terms of Service
            </a>
          </Link>{" "}
          and acknowledge having read the Dance Church{" "}
          <Link href="/legal/privacy">
            <a target="_blank" tabIndex={-1}>
              Privacy Policy
            </a>
          </Link>
          .
        </label>
      </div>
      {errorMessage && (
        <div className="form__row form__row--last form__row--right">
          <div className="form__error">{errorMessage}</div>
        </div>
      )}
      <div className="form__row form__row--stack">
        <button className="pill_btn" type="submit">
          {cta}
        </button>
      </div>
    </form>
  );
};
