import { APIResponse, get } from ".";
import { TaxApi } from "dcgo-contracts";

export const retrieveSalesTax = (
  request: TaxApi.RetrieveSalesTaxRequest
): Promise<APIResponse<TaxApi.RetrieveSalesTaxResponse>> =>
  get("/tax", {
    zipCode: request.zipCode,
    category: request.category,
    amount: `${request.amount}`,
  });
