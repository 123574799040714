import { CodeRedemptionApi, OfferingApi } from "dcgo-contracts";
import { ConfirmFreeMembership } from "./ConfirmFreeMembership";
import {
  CreateMembershipRequest,
  PromisedMembershipResponse,
} from "../useMembership";
import { getOfferingPromotion } from "../getOfferingPromotion";
import { SelectOffering } from "../SelectOffering";
import { StripeSubscriptionForm } from "../../stripe/StripeSubscriptionForm";
import { UseSelectPlan } from "../useSelectPlan";
import React, { ReactElement } from "react";
import styled from "styled-components";

const Header = styled.h4({
  fontSize: "18px",
  marginTop: 20,
  paddingTop: 11,
  paddingBottom: 11,
  borderTop: "1px solid rgba(255, 255, 255, 0.3)",
  borderBottom: "1px solid rgba(255, 255, 255, 0.3)",
});

export const CreateMembership = (props: {
  errorMessage?: string;
  selectedOffering?: OfferingApi.Offering["id"];
  selectPlan: UseSelectPlan;
  createMembership: (
    request: CreateMembershipRequest
  ) => PromisedMembershipResponse;
  hideTitle?: boolean;
  cancelButton?: ReactElement;
  onPromotionCodeApplied?: ({
    codeInfo,
    type,
  }: {
    codeInfo: CodeRedemptionApi.RedeemableCode;
    type: CodeRedemptionApi.RedeemableCodeType;
  }) => void;
}) => {
  const [
    {
      offerings,
      selectedOffering,
      promotion,
      promotionType,
      code,
      errorMessage,
      amountDueNow,
    },
    { applyPromotionCode, selectOffering },
  ] = props.selectPlan;

  if (promotionType === "gift") return null;

  const offeringPromotion = getOfferingPromotion(selectedOffering, promotion);

  return (
    (promotion && !promotion.paymentUpFront && selectedOffering && (
      <ConfirmFreeMembership
        errorMessage={props.errorMessage}
        promotion={promotion}
        onConfirm={async () => {
          const createMembershipRequest = {
            code,
            offering: selectedOffering,
            promotion,
          };
          await props.createMembership(createMembershipRequest);
        }}
        onCancel={() => applyPromotionCode("")}
      />
    )) || (
      <div className="form">
        <SelectOffering
          offerings={offerings}
          selectedOffering={selectedOffering}
          onOfferingSelected={selectOffering}
          hideTitle={props.hideTitle}
        />

        <Header>Payment Information</Header>

        {selectedOffering && (
          <StripeSubscriptionForm
            promotion={promotion}
            promotionCode={code}
            amountDueNow={amountDueNow}
            errorMessage={props.errorMessage || errorMessage}
            onProcessPaymentMethod={async (paymentMethodId: string) => {
              const result = await props.createMembership({
                code,
                offering: selectedOffering,
                paymentMethodId,
                promotion,
              });

              return result.ok
                ? { success: true }
                : { success: false, message: "" };
            }}
            onCodeEntered={applyPromotionCode}
            submitButtonText={
              offeringPromotion.isTrial ? "Start Free Trial" : "Subscribe"
            }
            cancelButton={props.cancelButton}
          />
        )}
      </div>
    )
  );
};
