import {
  CodeRedemptionApi,
  MembershipApi,
  PaymentMethodApi,
} from "dcgo-contracts";
import { DateTime } from "luxon";

type Membership = MembershipApi.Membership;
type PaymentMethod = PaymentMethodApi.PaymentMethod;
type RedeemableCode = CodeRedemptionApi.RedeemableCode;

export const formatDate = (dt: string) =>
  DateTime.fromISO(dt).toLocal().toLocaleString(DateTime.DATE_MED_WITH_WEEKDAY);

export const getPeriodEndMessage = (
  membership: Membership,
  paymentMethod?: PaymentMethod | null
) => {
  if (membership.phase === "free")
    return `You're part of the family! Enjoy your complementary access to dance church.`;

  if (!membership.transitionTime) return "";
  if (membership.trialUntil) {
    const trialEnd = formatDate(membership.trialUntil);
    if (paymentMethod) {
      return `Your card will not be charged until your free trial ends on ${trialEnd}.`;
    } else {
      return `Your free trial will end on ${trialEnd}. To keep dancing, add your card now.`;
    }
  }
  const periodEnd = formatDate(membership.transitionTime);

  if (membership.transition === "re-bill") {
    if (membership.phase === "discount" && membership.percentOff === 100) {
      if (!membership.discountUntil) return "";
      const discountEnd = formatDate(membership.discountUntil);
      return `Your free membership will end on ${discountEnd}.`;
    }
    const preamble = membership.numberOfGiftsInSchedule
      ? `After your ${
          membership.numberOfGiftsInSchedule === 1 ? "gift is" : "gifts are"
        } applied, your`
      : "Your";
    return `${preamble} membership will automatically renew on ${periodEnd}.`;
  }

  if (membership.transition === "standard") {
    if (paymentMethod) {
      return `Your card will not be charged until your gift membership ends on ${periodEnd}.`;
    } else {
      return `Your gift membership will end on ${periodEnd}. To keep dancing, add your card now.`;
    }
  }

  if (membership.transition === "cancel")
    return `Your membership will end on ${periodEnd} and you will not be charged again.`;

  return `Your membership will ${
    membership.transition || "end"
  } on ${periodEnd} and you will not be charged again.`;
};

interface CodeDescription {
  cta: string;
  headline: string;
  subhead: string;
  description: string[];
}

export const describePromotion = (
  codeInfo: RedeemableCode
): CodeDescription => {
  if (codeInfo.free === "forever") {
    return {
      headline: codeInfo.name,
      subhead: "Start your free membership",
      description: ["Welcome to the family. We're glad you're here."],
      cta: "Get Started",
    };
  }

  if (codeInfo.applicableOfferings.includes("unlimited-irl")) {
    return {
      headline: codeInfo.name,
      subhead: `${codeInfo.trialDays} Day Unlimited Membership`,
      description: [
        `Welcome to Dance Church!`,
        `You will have access to free In-Person classes for the next ${codeInfo.trialDays} days at go.dancechurch.com/irl, as well as access to live and on demand videos.`,
        `After ${codeInfo.trialDays} days your unlimited membership will end, however, you can still attend the In-Person classes that you registered for. You can also add a card to keep your membership active for $49 / month.`,
      ],
      cta: "Start Trial",
    };
  }

  if (codeInfo.trialDays) {
    return {
      headline: codeInfo.name,
      subhead: `Free ${codeInfo.trialDays} day trial`,
      description: [
        `Welcome to Dance Church!`,
        `You will have access to $10 off In-Person classes for you and a friend for the next ${codeInfo.trialDays} days at go.dancechurch.com/irl, as well as access to live and on demand videos. Add a card to keep your membership active, or switch to the Unlimited membership for $49 / month.`,
        `We'll send an email to remind you when your free trial is about to expire!`,
      ],
      cta: "Start Trial",
    };
  }

  if (codeInfo.durationMonths) {
    return {
      headline: `${codeInfo.durationMonths} Month Membership`,
      subhead: "Redeem Your Gift",
      description: [
        `Welcome to Dance Church!`,
        `You have received a ${codeInfo.durationMonths} month gift membership that will start once you press the Redeem button below.`,
        ` We'll send an email to remind you when this gift membership is about to expire.`,
      ],
      cta: "Redeem",
    };
  }

  return {
    headline: "Get Started",
    description: [`Welcome to Dance Church.`],
    subhead: "",
    cta: "Get Started",
  };
};
