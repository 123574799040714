import { CodeRedemptionApi, OfferingApi } from "dcgo-contracts";
import { debugLogger } from "~/lib/client/debugLogger";
import { getOfferingPromotion } from "./getOfferingPromotion";
import { retrieveCodeInfo } from "~/lib/client/api/codeRedemption";
import { retrieveOfferings } from "~/lib/client/api/offering";
import { useAutoApplyPromoCode } from "./useAutoApplyPromotionCode";
import { useCallback, useEffect, useState } from "react";
const debug = debugLogger("useSelectPlan");

type Offering = OfferingApi.Offering;
type RedeemableCode = CodeRedemptionApi.RedeemableCode;
type RedeemableCodeType = CodeRedemptionApi.RedeemableCodeType;

const realApi = {
  retrieveCodeInfo,
  retrieveOfferings,
};

export type SelectPlanApi = typeof realApi;
export interface SelectPlanState {
  selectedOffering: Offering | null;
  offerings: Offering[];
  errorMessage: string;
  promotion: RedeemableCode | null;
  promotionType: RedeemableCodeType | null;
  code: string;
  amountDueNow: number;
}

export interface SelectPlanActions {
  selectOffering: (id: string | null) => void;
  applyPromotionCode: (code: string) => Promise<boolean>;
  clearErrors: (code: string) => void;
}

export type UseSelectPlan = [SelectPlanState, SelectPlanActions];

export const getUseSelectPlan = (
  api: SelectPlanApi,
  useAutoApplyPromoCode_: typeof useAutoApplyPromoCode
) => (props: {
  selectedOffering?: { id: string };
}): [SelectPlanState, SelectPlanActions] => {
  const [offerings, setOfferings] = useState<Offering[]>([]);

  const codeFromQuery = useAutoApplyPromoCode_();
  const [code, setCode] = useState("");
  const [selectedOffering, setSelectedOffering] = useState<Offering | null>(
    null
  );
  const [promotion, setPromotion] = useState<RedeemableCode | null>(null);
  const [promotionType, setPromotionType] = useState<RedeemableCodeType | null>(
    null
  );
  const [errors, setErrors] = useState<string[]>([]);
  const [initialSelectedOfferingId] = useState(
    props.selectedOffering?.id || ""
  );

  const clearErrors = () => setErrors([]);

  /**
   * looks up code, sets relevant state, and returns whether it's valid
   */
  const applyPromotionCode = useCallback(
    async (code: string) => {
      setPromotion(null);
      setCode("");
      clearErrors();

      if (!code) return false;
      try {
        const { codeInfo, type } = await api.retrieveCodeInfo(code);
        setCode(code);
        setPromotion(codeInfo);
        setPromotionType(type);

        if (!codeInfo.paymentUpFront && codeInfo.applicableOfferings.length) {
          const applicableOffering = offerings.find((o) =>
            codeInfo.applicableOfferings.includes(o.id)
          );
          if (applicableOffering) {
            setSelectedOffering(applicableOffering);
          }
        }
        return true;
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } catch (error: any) {
        debug(error);
        setErrors([error.message]);
        return false;
      }
    },
    [offerings]
  );

  useEffect(() => {
    api.retrieveOfferings().then((response) => {
      if (response.ok) {
        setOfferings(response.data.offerings);
        const defaultOffering = response.data.offerings[0];
        if (initialSelectedOfferingId) {
          const offering = response.data.offerings.find(
            (o) => o.id === initialSelectedOfferingId
          );
          if (offering) {
            setSelectedOffering(offering);
          }
        } else {
          setSelectedOffering(defaultOffering);
        }
      } else {
        setErrors(["Oops. Something went wrong."]);
      }
    });
  }, [initialSelectedOfferingId]);

  useEffect(() => {
    applyPromotionCode(codeFromQuery);
  }, [codeFromQuery, applyPromotionCode]);

  const offeringPromotion = getOfferingPromotion(selectedOffering, promotion);
  return [
    {
      selectedOffering,
      promotion,
      promotionType,
      offerings,
      errorMessage: errors[0] || "",
      code,
      amountDueNow: offeringPromotion.totalDue,
    },
    {
      selectOffering: (id: string | null) => {
        if (id === null) {
          setSelectedOffering(null);
          return;
        }
        const matching = offerings.find((o) => o.id === id);
        if (matching) {
          setSelectedOffering(matching);
        }
      },
      applyPromotionCode,
      clearErrors,
    },
  ];
};

export const useSelectPlan = getUseSelectPlan(realApi, useAutoApplyPromoCode);
