import { PaymentMethodFieldProps } from "../types";
import { useCaptureEscapeKey } from "~/lib/client/useCaptureEscapeKey";
import CloseIcon from "~/images/close.svg";
import React, { useEffect, useState } from "react";

interface PromotionCodeModalProps extends PaymentMethodFieldProps {
  /** The initial promotion code to display, if any. */
  initialPromotionCode: string | null;
  onCodeEntered: (code: string) => Promise<boolean>;
  onClose: () => void;
  errorMessage?: string;
}

export const PromotionCodeModal: React.FC<PromotionCodeModalProps> = (
  props
) => {
  const [promotionCode, setPromotionCode] = useState<string | null>(null);

  useEffect(() => {
    if (props.initialPromotionCode) {
      setPromotionCode(props.initialPromotionCode);
    }
  }, [props.initialPromotionCode]);

  useCaptureEscapeKey(() => props.onClose());
  return (
    <div className="promo-overlay">
      <div
        className="promo form"
        role="dialog"
        aria-labelledby="promoCodeModalTitle"
      >
        <div className="promo__header">
          <h3
            className="heading_h3 promo__header__title"
            id="promooCodeModalTitle"
          >
            Enter a promo code
          </h3>
          <button type="button" onClick={() => props.onClose()}>
            <CloseIcon />
          </button>
        </div>
        <form
          onSubmit={async (e) => {
            e.preventDefault();
            const isValid = await props.onCodeEntered(promotionCode || "");
            if (isValid) {
              props.onClose();
            }
          }}
        >
          <div className="form__row form__row--first form__row--between">
            <label className="form__row__label" htmlFor="promotionCode">
              Promo Code
            </label>
            <span className="form__row__value form__row__value--padded">
              <input
                // only one input in the form; ok to autofocus
                // eslint-disable-next-line jsx-a11y/no-autofocus
                autoFocus
                className="form__input uppercase"
                id="promotionCode"
                type="text"
                name="promotionCode"
                placeholder="CODE"
                onChange={(e) => setPromotionCode(e.target.value)}
                defaultValue={promotionCode || ""}
              />
            </span>
          </div>
          {props.errorMessage && (
            <div className="form__row form__row--last form__row--between">
              <div className="form__error">{props.errorMessage}</div>
            </div>
          )}
          <div className="form__row form__row--last form__row--between">
            <button className="pill_btn">Apply</button>

            <button
              type="button"
              className="link link--stealth promo__cancel-button"
              onClick={() => props.onClose()}
            >
              I don&apos;t have a promo code
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};
