import { CodeRedemptionApi } from "dcgo-contracts";
import { FormRow } from "../../FormLayout";
import { ForwardText } from "~/components/layout";
import { PaymentMethodFieldProps } from "../types";
import { PromoCodeBanner } from "./PromoCodeBanner";
import { SalesTaxCalculation } from "~/lib/client/useSalesTax";
import { TotalDisplay } from "../TotalDisplay";
import clsx from "clsx";
import React from "react";

interface TotalRowProps extends PaymentMethodFieldProps {
  /** The subscription offering on display. */
  amountDueNow: number;

  salesTax: SalesTaxCalculation;

  /** The currently applied promotion code */
  promotion: CodeRedemptionApi.RedeemableCode | null;
  promotionCode?: string;

  /** Callback invoked when user clicks on the "Use Promotion Code" link. */
  onUsePromotionCode: () => void;
}

export const TotalRow: React.FC<TotalRowProps> = ({
  amountDueNow,
  salesTax,
  promotion,
  promotionCode = "",
  onUsePromotionCode,
}) => {
  const presentablePromoCode = promotion?.presentable && promotionCode;

  return (
    <>
      <FormRow label="Total Due Now">
        <TotalDisplay total={amountDueNow} salesTax={salesTax} />
        <button
          className={clsx("cta_btn hoverhype", {
            purple: presentablePromoCode,
          })}
          onClick={onUsePromotionCode}
          type="button"
        >
          <ForwardText>
            {presentablePromoCode
              ? `"${promotionCode.toUpperCase()}" APPLIED`
              : "Use a promo code"}
          </ForwardText>
        </button>
      </FormRow>
      {presentablePromoCode && (
        <PromoCodeBanner promotionCode={promotionCode} />
      )}
    </>
  );
};
