import { CodeRedemptionApi } from "dcgo-contracts";
import { paymentFormText } from "~/components/paymentFormText";
import { PaymentMethodForm } from "../PaymentMethodForm";
import { ProcessPaymentMethodResult } from "../types";
import { PromotionCodeModal } from "./PromotionCodeModal";
import { TermsOfServiceField } from "../TermsOfServiceField";
import { TotalRow } from "./TotalRow";
import { useSalesTax } from "~/lib/client/useSalesTax";
import React, { ReactElement, useState } from "react";

interface StripeSubscriptionFormProps {
  /** Metadata about the offered subscription. */
  amountDueNow: number;
  errorMessage?: string;

  /** Callback sent when the subscription sign-up form is successful. */
  onSuccess?: () => void;

  /** If provided, a cancel link will be displayed and this will be called if clicked. */
  onCancel?: () => void;
  cancelButton?: ReactElement;
  onCodeEntered?: (code: string) => Promise<boolean>;
  onPaymentMethod?: (token: string) => void;
  onProcessPaymentMethod?: (
    paymentMethodId: string
  ) => Promise<ProcessPaymentMethodResult>;

  /** The current promotion code */
  promotionCode?: string;
  promotion: CodeRedemptionApi.RedeemableCode | null;

  submitButtonText?: string;
}

export const StripeSubscriptionForm: React.FC<StripeSubscriptionFormProps> = ({
  amountDueNow,
  cancelButton,
  errorMessage,
  onCancel,
  onCodeEntered,
  onPaymentMethod,
  onProcessPaymentMethod,
  promotion,
  promotionCode,
  submitButtonText = "Join",
}) => {
  // CONSIDER: by the time this component is used, we assume isLoading is always false.
  const [showPromotionCode, setShowPromotionCode] = useState<boolean>(false);

  const [zipCode, setZipCode] = useState("");

  const salesTax = useSalesTax({
    amount: amountDueNow,
    category: "subscription",
    zipCode,
  });

  return (
    <>
      <PaymentMethodForm
        errorMessage={errorMessage}
        buttonText={submitButtonText}
        onPaymentMethod={onPaymentMethod}
        onProcessPaymentMethod={onProcessPaymentMethod}
        onZipCode={setZipCode}
        onCancel={onCancel}
        cancelButton={cancelButton}
        finePrint={paymentFormText.subscriptionPayment}
      >
        <TotalRow
          key="totalRow"
          promotion={promotion}
          promotionCode={promotionCode}
          amountDueNow={amountDueNow}
          salesTax={salesTax}
          onUsePromotionCode={() => setShowPromotionCode(true)}
        />
        <TermsOfServiceField key="termsOfServiceField" />
      </PaymentMethodForm>
      {showPromotionCode && (
        <PromotionCodeModal
          key="promotionCodeField"
          initialPromotionCode=""
          onCodeEntered={onCodeEntered || (() => Promise.resolve(false))}
          onClose={() => setShowPromotionCode(false)}
          errorMessage={errorMessage}
        />
      )}
    </>
  );
};
